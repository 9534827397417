import { Store, Service } from "@retailtune/types/lib/store";
import { AnalyticsCategory, AnalyticsAction } from "@retailtune/types/lib/analytics";
import { Position } from "@retailtune/types/lib/geolocation";
import { CTAOrigin } from "@retailtune/types/lib/analytics";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Translations } from "../common/translations";
import {
  USER_POSITION_CONSENT,
  createStoreOpeningTimeText,
  fetchUserPosition,
  fetchUserDefaultPosition,
  getDevice,
  getExpirable,
  getRadialDistanceFn,
  positionToLatLngLiteral,
  sortStoresByDistance,
  createAutocompleteHandler,
  retailTuneAutocompleteHandler,
  createPosition,
  createDebounceFn,
  setExpirable,
  createExpirationValue,
  RT_API_STORES_GET,
  RT_API_SERVICES_GET,
} from "@retailtune/utils";
import {
  CustomRenderer,
  getGoogleMapsLink,
  googleAutocompleteHandler,
  selectMapZoomFromPredictionLayer,
} from "@retailtune/google-maps-utils";
import { mapStyle } from "../common/mapStyle";
import { PredictionData } from "@retailtune/types/lib/autocomplete";
import {
  createAutocomplete,
  createScrollButton,
  createSidebar,
  createToastMessagesContainer,
} from "@retailtune/vanilla-ui-core";

import "@retailtune/vanilla-ui-core/styles/autocomplete/Autocomplete.css";
import "@retailtune/vanilla-ui-core/styles/back-to-top/Back-to-top.css";
import "@retailtune/vanilla-ui-core/styles/sidebar/Sidebar.css";
import "@retailtune/vanilla-ui-core/styles/toast/Toast.css";
import { ActiveLanguage } from "../common/languages";

interface PositionState {
  defaultPosition: Position;
  userPosition: Position;
  currentPosition: Position;
}

interface StoreFilter {
  storeType: string | null;
}

declare function sendGa4DL(category: AnalyticsCategory, action: AnalyticsAction, label: string, storeId: number): void;
declare const retailtune: {
  rtKey: string;
  language: string;
  allLanguages: ActiveLanguage[];
  // server time in milliseconds
  serverTime: number;
  weekdayNames: string[];
  // retailtune domain URL
  assetBaseUrl: string;
  // integration domain URL
  homepageUrl: string;
  translations: Translations;
  storesFilter: StoreFilter;
};

// user device type
let currentDevice = getDevice();

// toast trigger function
let toast: (message: string) => void;
// update the element to listen to for scroll
let updateScrollingElement: (newElement: string | HTMLElement) => void;
let showLanguageSidebar: (shouldShow: boolean) => void;

let defaultPosition: Position;
let position: PositionState;

let allStores: Store[];
let filteredStores: Store[];
const storeMarkersMap = new Map<string, google.maps.Marker>();
const storeCardsMap = new Map<string, HTMLLIElement>();
let uniqueCountriyTags: string[];

let googleMap: google.maps.Map;
let userMarker: google.maps.Marker;
let infoWindow: google.maps.InfoWindow;
let lastClickedMarker: { store: Store; origin: CTAOrigin } | null;
let markerCluster: MarkerClusterer;
// driving directions related
let directionsDestinationStoreCode: string | null;
let directionsOriginPosition: Position;
let directionsDestinationPosition: Position;
let destinationStoreMarker: google.maps.Marker;
let travelMode: google.maps.TravelMode;
let directionsInstructions: google.maps.DirectionsStep[];
let directionsService: google.maps.DirectionsService;
let directionsRenderer: google.maps.DirectionsRenderer;

// consent related
let userPositionConsent = !!getExpirable<boolean>(USER_POSITION_CONSENT);

// tells whether the user has already been geolocated or not
let positionHasBeenFetched = false;

// # Main function
async function main() {
  [defaultPosition, allStores] = await Promise.all([fetchUserDefaultPosition(retailtune.rtKey), fetchAllStores()]);

  position = {
    defaultPosition,
    userPosition: defaultPosition,
    currentPosition: defaultPosition,
  };

  directionsOriginPosition = defaultPosition;
  const countryTagsSet = new Set<string>();

  for (let i = 0; i < allStores.length; ++i) {
    const store = allStores[i];

    // save store country tag
    countryTagsSet.add(store.country.tagISO31661Alpha2);

    // creating and caching the store card for this store
    const storeCard = createStoreCard(store);
    storeCardsMap.set(store.storeCode, storeCard);

    // creating and caching the marker for this store
    const marker = new google.maps.Marker({
      position: { lat: store.latitude, lng: store.longitude },
      icon: `${retailtune.assetBaseUrl}/img/pin/pin-store.svg`,
    });
    marker.addListener("click", () => {
      infoWindow.setContent(createInfoWindowContent(store));
      infoWindow.open({ anchor: marker });

      if (lastClickedMarker) {
        // user clicked on store card and triggered the callback
        googleMap.setCenter({ lat: store.latitude, lng: store.longitude });
        let newZoom = googleMap.getZoom();
        newZoom = newZoom ? (newZoom > 12 ? newZoom : 12) : 0;
        googleMap.setZoom(newZoom);
      } else {
        // user directly clicked on the store marker
        lastClickedMarker = { store, origin: "map" };
      }
      // ? needed to make the store card scroll into view, see "handleBoundsChange"
      google.maps.event.trigger(googleMap, "bounds_changed");
    });
    storeMarkersMap.set(store.storeCode, marker);
  }

  uniqueCountriyTags = Array.from(countryTagsSet);

  // # Map initialization
  const mapEl = document.getElementById("rt_map")!;
  const mapCenter = positionToLatLngLiteral(position.currentPosition);

  googleMap = new google.maps.Map(mapEl, {
    zoom: 7,
    center: mapCenter,
    mapTypeControl: false,
    streetViewControl: false,
    styles: mapStyle,
  });

  userMarker = new google.maps.Marker({
    map: googleMap,
    position: mapCenter,
    icon: `${retailtune.assetBaseUrl}/img/pin/pin-user.svg`,
  });

  infoWindow = new google.maps.InfoWindow();

  markerCluster = new MarkerClusterer({
    map: googleMap,
    markers: [],
    renderer: new CustomRenderer(`${retailtune.assetBaseUrl}/img/pin/cluster.svg`, "#000", () => {
      if (infoWindow) {
        infoWindow.close();
      }
    }),
  });

  // # Driving Directions initialization
  destinationStoreMarker = new google.maps.Marker({
    map: googleMap,
    visible: false,
    icon: `${retailtune.assetBaseUrl}/img/pin/pin-store.svg`,
  });

  directionsService = new google.maps.DirectionsService();
  directionsRenderer = new google.maps.DirectionsRenderer({
    map: googleMap,
    polylineOptions: {
      strokeColor: "#000",
      strokeWeight: 3,
    },
  });

  // # Travel modes initialization
  const travelModesEl = document.getElementById("rt_travel_modes")!;
  travelModesEl.innerHTML = "";

  const createTravelMode = (mode: google.maps.TravelMode, iconUrl: string) => {
    const travelModeEl = document.createElement("button");
    travelModeEl.id = mode;
    travelModeEl.classList.add("rt-directions-pane__travel-mode");
    if (mode === google.maps.TravelMode.DRIVING) {
      travelModeEl.classList.add("rt-directions-pane__travel-mode--selected");
    }
    const imgEl = document.createElement("img");
    imgEl.src = iconUrl;
    imgEl.alt = mode;
    imgEl.width = 40;
    imgEl.height = 40;

    travelModeEl.onclick = () => {
      document
        .querySelectorAll(".rt-directions-pane__travel-mode")
        .forEach(el => el.classList.remove("rt-directions-pane__travel-mode--selected"));
      travelModeEl.classList.add("rt-directions-pane__travel-mode--selected");
      travelMode = mode;
      updateMapWithDirections();
    };
    travelModeEl.appendChild(imgEl);
    travelModesEl.appendChild(travelModeEl);
  };

  createTravelMode(google.maps.TravelMode.DRIVING, `${retailtune.assetBaseUrl}/img/icon/driving.svg`);
  createTravelMode(google.maps.TravelMode.WALKING, `${retailtune.assetBaseUrl}/img/icon/walking.svg`);
  createTravelMode(google.maps.TravelMode.TRANSIT, `${retailtune.assetBaseUrl}/img/icon/transit.svg`);

  travelMode = google.maps.TravelMode.DRIVING;

  // # Autocomplete initialization

  // shared Autocomplete search handler
  const searchHandler = createAutocompleteHandler(
    retailTuneAutocompleteHandler(retailtune.rtKey, {
      language: retailtune.language,
      countries: uniqueCountriyTags,
    }),
    googleAutocompleteHandler()
  );

  // main autocomplete prediction click handler
  const mainPredictionClickHandler = (prediction: PredictionData) => {
    position.currentPosition = createPosition({
      latitude: prediction.latitude,
      longitude: prediction.longitude,
    });

    if (infoWindow) {
      infoWindow.close();
    }

    const latLng = positionToLatLngLiteral(position.currentPosition);
    userMarker.setPosition(latLng);
    googleMap.setCenter(latLng);
    googleMap.setZoom(selectMapZoomFromPredictionLayer(prediction));

    if (directionsDestinationStoreCode) {
      clearDrivingDirections();
      filterStores();
    }
    updateStores();

    sendGa4DL("StoreLocator", "Click", "FreeSearch", 0);
  };

  createAutocomplete({
    anchor: "rt_main_autocomplete",
    searchHandler,
    predictionClickHandler: mainPredictionClickHandler,
    zeroResultsMessage: retailtune.translations.k_autocomplete_zero_results_message,
    placeholder: retailtune.translations.k_autocomplete_placeholder,
    searchIcon: {
      path: `${retailtune.assetBaseUrl}/img/icon/search.svg`,
      position: "right",
    },
  });

  // directions autocomplete prediction click handler
  const directionsPredictionClickHandler = (prediction: PredictionData) => {
    directionsOriginPosition = createPosition({
      latitude: prediction.latitude,
      longitude: prediction.longitude,
    });
    updateMapWithDirections();
    sendGa4DL("StoreLocator", "Click", "FreeSearchDirections", 0);
  };

  createAutocomplete({
    anchor: "rt_directions_autocomplete_container",
    searchHandler,
    predictionClickHandler: directionsPredictionClickHandler,
    zeroResultsMessage: retailtune.translations.k_autocomplete_zero_results_message,
    placeholder: retailtune.translations.k_autocomplete_placeholder,
  });

  [showLanguageSidebar] = createSidebar({
    anchor: "rt_storelocator",
    position: "right",
    content: createLanguagesSidebarcontent(),
  });

  [toast] = createToastMessagesContainer({
    anchor: "rt_storelocator",
    autoClose: 3000,
    position: "top-right",
  });

  [updateScrollingElement] = createScrollButton({
    anchorEl: "rt_stores_area",
    scrollingEl: "rt_stores_area",
  });

  if (currentDevice === "desktop") {
    const params = new URLSearchParams(window.location.search);
    const storeCode = params.get("code");
    directionsDestinationStoreCode = storeCode;
  }

  filterStores();
  showStoresOrDirections();

  showConsentModal(!userPositionConsent);
  if (userPositionConsent) {
    await getUserPosition();
    showStoresOrDirections();
  }

  // # Binding event handlers

  const windowResizeDebounce = createDebounceFn()(100);
  window.onresize = () =>
    windowResizeDebounce(() => {
      const newDevice = getDevice();

      // from tablet/mobile to desktop
      if (currentDevice !== "desktop" && newDevice === "desktop") {
        updateScrollingElement("rt_storelocator");
        // from desktop to tablet/mobile
      } else if (currentDevice === "desktop" && newDevice !== "desktop") {
        updateScrollingElement("rt_stores_area");
      }

      currentDevice = newDevice;
    });

  const boundsChangedDebounce = createDebounceFn()(300);
  googleMap.addListener("bounds_changed", () =>
    boundsChangedDebounce(() => {
      // do nothing if map is showing driving direcitons
      if (directionsDestinationStoreCode) {
        return;
      }
      updateStores();
      if (lastClickedMarker) {
        const { store, origin } = lastClickedMarker;
        lastClickedMarker = null;

        const storesCard = document.getElementById(`rt_store_card_${store.storeCode}`);
        if (storesCard && currentDevice === "desktop") {
          storesCard.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }

        const eventName = origin === "list" ? "StoreClickListing" : "StoreClickMap";
        sendGa4DL(
          "Store",
          "Click",
          `${eventName}-${store.city} ${store.address1}-${currentDevice}-${retailtune.language}`,
          store.id
        );
      }
    })
  );

  const btnNearestStoreEl = document.getElementById("rt_nearest_stores_btn")!;
  btnNearestStoreEl.onclick = async () => {
    showConsentModal(!userPositionConsent);
    if (userPositionConsent) {
      infoWindow.close();
      if (directionsDestinationStoreCode) {
        clearDrivingDirections();
        filterStores();
      }
      await getUserPosition();
      updateStores();
    }
    sendGa4DL("StoreLocator", "Click", "FindNearestStore", 0);
  };

  const languageSidebarBtn = document.getElementById("rt_language_sidebar_btn");
  if (languageSidebarBtn) {
    languageSidebarBtn.onclick = () => showLanguageSidebar(true);
  }

  const directionsPaneCloseIcon = document.getElementById("rt_directions_pane_close_btn")!;
  directionsPaneCloseIcon.onclick = () => {
    clearDrivingDirections();
    filterStores();
    updateStores();
  };

  const btnConsentModalYes = document.getElementById("rt_btn_consent_modal_yes")!;
  btnConsentModalYes.onclick = async () => {
    userPositionConsent = true;
    setExpirable(USER_POSITION_CONSENT, {
      value: JSON.stringify(true),
      expiration: createExpirationValue(1, "years"),
    });

    await getUserPosition();
    showStoresOrDirections();
    showConsentModal(false);

    sendGa4DL("StoreLocator", "Geo", "Agree", 0);
  };

  const btnConsentModalNo = document.getElementById("rt_btn_consent_modal_no")!;
  btnConsentModalNo.onclick = () => {
    userPositionConsent = false;
    showConsentModal(false);

    sendGa4DL("StoreLocator", "Geo", "Disagree", 0);
  };
}

async function getUserPosition() {
  const newPosition = await fetchUserPosition(position.defaultPosition);
  // updating position info with the new user position
  position.userPosition = newPosition;
  position.currentPosition = newPosition;
  directionsOriginPosition = newPosition;

  const latLng = positionToLatLngLiteral(position.currentPosition);
  userMarker.setPosition(latLng);
  googleMap.setCenter(latLng);

  if (!positionHasBeenFetched) {
    switch (newPosition.type) {
      case "html5":
        newPosition.origin === "fetched"
          ? sendGa4DL("StoreLocator", "Geo", "Success", 0)
          : sendGa4DL("StoreLocator", "Geo", "SuccessCookies", 0);
        break;
      case "ip":
        toast(retailtune.translations.k_warning_position_generic_error);
        newPosition.origin === "fetched"
          ? sendGa4DL("StoreLocator", "GeoIP", "Success", 0)
          : sendGa4DL("StoreLocator", "GeoIP", "SuccessCookies", 0);
        break;
      case "default":
        toast(retailtune.translations.k_warning_position_generic_error);
        sendGa4DL("StoreLocator", "GeoDefault", "Success", 0);
        break;
    }

    positionHasBeenFetched = true;
  }
}

async function fetchAllStores() {
  const response = await fetch(RT_API_STORES_GET, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${retailtune.rtKey}`,
    },
    body: JSON.stringify({ language: retailtune.language }),
  });
  if (response.status !== 200) throw new Error(`[ERROR] cannot fetch stores (status ${response.status})`);

  return await response.json();
}

async function fetchAllServices() {
  const response = await fetch(RT_API_SERVICES_GET, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${retailtune.rtKey}`,
    },
    body: JSON.stringify({ language: retailtune.language }),
  });
  if (response.status !== 200) throw new Error(`[ERROR] cannot fetch services (status ${response.status})`);

  return await response.json();
}

function filterStores() {
  // update filteredStores array
  if (retailtune.storesFilter.storeType === null) {
    filteredStores = new Array<Store>(allStores.length);
    for (let i = 0; i < allStores.length; ++i) {
      filteredStores[i] = allStores[i];
    }
  } else {
    filteredStores = [];
    for (let i = 0; i < allStores.length; ++i) {
      if (allStores[i].storeTypeLabels.some(type => retailtune.storesFilter.storeType === type)) {
        filteredStores.push(allStores[i]);
      }
    }
  }

  // update markerCluster markers
  const markers = new Array<google.maps.Marker>(filteredStores.length);
  for (let i = 0; i < filteredStores.length; ++i) {
    markers[i] = storeMarkersMap.get(filteredStores[i].storeCode)!;
  }
  markerCluster.clearMarkers();
  markerCluster.addMarkers(markers);
}

function updateStores() {
  const mapBounds = googleMap.getBounds();
  const mapCenter = googleMap.getCenter();

  // make sure map bounds are defined before continuing execution
  if (!(mapBounds && mapCenter)) {
    setTimeout(updateStores, 50);
    return;
  }

  const visibleStores: Store[] = [];
  const getDistance = getRadialDistanceFn(mapCenter.lat(), mapCenter.lng());

  const latLng = { lat: 0, lng: 0 };
  for (let i = 0; i < filteredStores.length; ++i) {
    // populating store cards
    latLng.lat = filteredStores[i].latitude;
    latLng.lng = filteredStores[i].longitude;
    if (mapBounds.contains(latLng)) {
      filteredStores[i].distance = getDistance(latLng.lat, latLng.lng);
      visibleStores.push(filteredStores[i]);
    }
  }
  visibleStores.sort(sortStoresByDistance);

  const storesEl = document.getElementById("rt_stores")!;
  storesEl.innerHTML = "";
  for (let i = 0; i < visibleStores.length; ++i) {
    storesEl.appendChild(storeCardsMap.get(visibleStores[i].storeCode)!);
  }

  // Update text showing filtered stores number
  const labelStoresCountEl = document.getElementById("rt_stores_count")!;

  labelStoresCountEl.innerHTML =
    visibleStores.length === 1
      ? `<output>1</output><span>${retailtune.translations.k_store_found}</span>`
      : `<output>${visibleStores.length}</output><span>${retailtune.translations.k_stores_found}</span>`;
}

function createStoreCard(store: Store) {
  function handleElementClick() {
    const marker = storeMarkersMap.get(store.storeCode);
    if (!marker) return;
    lastClickedMarker = { store, origin: "list" };
    google.maps.event.trigger(marker, "click");
  }

  // assuming servertime is already in milliseconds (as it should be)
  const todayTimeMilliseconds = retailtune.serverTime + store.hourOffset * 1000;
  let serverDayIndex = new Date(todayTimeMilliseconds).getDay();
  serverDayIndex = serverDayIndex === 0 ? 6 : serverDayIndex - 1;
  const dayname = retailtune.weekdayNames[serverDayIndex];

  const openingTimeText = createStoreOpeningTimeText(
    retailtune.serverTime,
    store,
    dayname,
    retailtune.translations.k_opening_time_closed,
    true
  );

  return (
    <li id={`rt_store_card_${store.storeCode}`} class="rt-store" onclick={handleElementClick}>
      <article>
        {store.utility === 2 && <div class="rt-next-opening">{retailtune.translations.k_next_opening}</div>}
        <h2 class="rt-store__name">{store.name}</h2>
        <div class="rt-store__info">
          <span class="rt-address">
            {store.address1} - {store.postalCode} {store.city}
          </span>
          {store.phone && (
            <a
              class="rt-phone"
              href={`tel:${store.phone}`}
              onclick={(e: Event) => {
                e.stopPropagation();
                handlePhoneClick(store, "list");
              }}
            >
              {store.phone}
            </a>
          )}
          {store.whatsapp && (
            <a
              class="rt-whatsapp"
              href={`https://wa.me/${store.whatsapp}`}
              onclick={(e: Event) => {
                e.stopPropagation();
                handleWhatsappClick(store, "list");
              }}
            >
              {retailtune.translations.k_store_chat_now}
            </a>
          )}
          {openingTimeText && <span class="rt-opening-time" innerHTML={openingTimeText}></span>}
        </div>
        <div class="rt-store__cta">
          {store.storeLink && (
            <a
              class="rt-btn rt-btn-primary"
              href={store.storeLink}
              onclick={(e: Event) => {
                e.stopPropagation();
                handleStoreInfoClick(store, "list");
              }}
            >
              {retailtune.translations.k_store_details}
            </a>
          )}
          <button
            class="rt-btn rt-btn-secondary"
            onclick={(e: Event) => {
              e.stopPropagation();
              handleDrivingDirectionsClick(store, "list");
            }}
          >
            {retailtune.translations.k_store_driving_directions}
          </button>
        </div>
      </article>
    </li>
  );
}

function createInfoWindowContent(store: Store) {
  return (
    <article class="rt-iw">
      {store.utility === 2 && <span class="rt-next-opening">{retailtune.translations.k_next_opening}</span>}
      <h2 class="rt-iw__name">{store.name}</h2>
      <div class="rt-iw__info">
        <span class="rt-address">
          {store.address1} - {store.postalCode} {store.city}
        </span>
        {store.phone && (
          <a class="rt-phone" href={`tel:${store.phone}`} onclick={() => handlePhoneClick(store, "map")}>
            {store.phone}
          </a>
        )}
        {store.whatsapp && (
          <a
            class="rt-whatsapp"
            href={`https://wa.me/${store.whatsapp}`}
            onclick={() => handleWhatsappClick(store, "map")}
          >
            {retailtune.translations.k_store_chat_now}
          </a>
        )}
      </div>
      <div class="rt-iw__cta">
        {store.storeLink && (
          <a class="rt-btn rt-btn-primary" href={store.storeLink} onclick={() => handleStoreInfoClick(store, "map")}>
            {retailtune.translations.k_store_details}
          </a>
        )}
        <button class="rt-btn rt-btn-tertiary" onclick={() => handleDrivingDirectionsClick(store, "map")}>
          {retailtune.translations.k_store_driving_directions}
        </button>
      </div>
    </article>
  );
}

function createLanguagesSidebarcontent() {
  return (
    <div class="rt-sidebar-wrapper">
      <header class="rt-sidebar__header">{retailtune.language.toUpperCase()}</header>
      <ul class="rt-sidebar__body">
        {retailtune.allLanguages.map(lang => (
          <li
            class={`rt-sidebar__language ${lang.code === retailtune.language ? "rt-sidebar__language--current" : ""}`}
          >
            <a href={`${retailtune.homepageUrl}/${lang.code}`}>{lang.name}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

function updateMapWithDirections() {
  const destinationStore = allStores.find(s => s.storeCode === directionsDestinationStoreCode);
  if (!destinationStore) return;

  directionsDestinationPosition = createPosition({
    latitude: destinationStore.latitude,
    longitude: destinationStore.longitude,
  });

  const origin = positionToLatLngLiteral(directionsOriginPosition);
  const destination = positionToLatLngLiteral(directionsDestinationPosition);
  const request: google.maps.DirectionsRequest = {
    origin,
    destination,
    travelMode,
  };

  directionsService.route(request, (result, status) => {
    if (
      !result ||
      status === google.maps.DirectionsStatus.INVALID_REQUEST ||
      status === google.maps.DirectionsStatus.MAX_WAYPOINTS_EXCEEDED ||
      status === google.maps.DirectionsStatus.ZERO_RESULTS
    ) {
      return toast(retailtune.translations.k_warning_directions_no_results);
    }
    if (status === google.maps.DirectionsStatus.OK) {
      clearDrivingDirectionsInstructions();
      markerCluster.clearMarkers();
      infoWindow.close();
      showDirectionsPane(true);

      const route = result.routes[0].legs[0];

      directionsInstructions = route.steps;
      directionsRenderer.setOptions({
        directions: result,
        suppressMarkers: true,
      });
      userMarker.setPosition(origin);
      destinationStoreMarker.setPosition(destination);
      destinationStoreMarker.setVisible(true);

      // updating the directions pane UI
      const destinationInputEl = document.getElementById("rt_destination_input_text")!;
      destinationInputEl.textContent = `${destinationStore.name} - ${destinationStore.city}`;

      const destinationLabelTextEl = document.getElementById("rt_destination_label_text")!;
      destinationLabelTextEl.textContent = `${destinationStore.address1}, ${destinationStore.city}`;

      const directionsInstructionsEl = document.getElementById("rt_directions_instructions")!;
      for (let i = 0; i < directionsInstructions.length; ++i) {
        const step = document.createElement("li");
        step.classList.add("rt-instruction");
        step.innerHTML = `<strong>${i + 1}.</strong> <div>${directionsInstructions[i].instructions}</div>`;
        directionsInstructionsEl.appendChild(step);
      }
    }
  });
}

function showStoresOrDirections() {
  if (directionsDestinationStoreCode && currentDevice === "desktop") updateMapWithDirections();
  else updateStores();
}

function showConsentModal(shouldShow: boolean) {
  const consentModalEl = document.getElementById("rt_position_consent_modal")!;
  if (shouldShow) {
    consentModalEl.classList.add("rt-modal--visible");
  } else {
    consentModalEl.classList.remove("rt-modal--visible");
  }
}

function showDirectionsPane(shouldShow: boolean) {
  const directionsPaneEl = document.getElementById("rt_directions_pane")!;
  if (shouldShow) {
    directionsPaneEl.classList.add("rt-directions-pane--visible");
    updateScrollingElement("rt_directions_pane");
  } else {
    directionsPaneEl.classList.remove("rt-directions-pane--visible");
    updateScrollingElement("rt_stores_area");
  }
}

function clearDrivingDirectionsInstructions() {
  directionsInstructions = [];
  const directionsInstructionsEl = document.getElementById("rt_directions_instructions")!;
  directionsInstructionsEl.innerHTML = "";
}

function clearDrivingDirections() {
  showDirectionsPane(false);
  directionsDestinationStoreCode = null;
  directionsRenderer.setDirections({ routes: [] });
  clearDrivingDirectionsInstructions();
  // reset travel mode value and appearance
  travelMode = google.maps.TravelMode.DRIVING;
  document
    .querySelectorAll(".rt-directions-pane__travel-mode")
    .forEach(el => el.classList.remove("rt-directions-pane__travel-mode--selected"));
  document.getElementById(google.maps.TravelMode.DRIVING)!.classList.add("rt-directions-pane__travel-mode--selected");
  destinationStoreMarker.setVisible(false);
  userMarker.setIcon(`${retailtune.assetBaseUrl}/img/pin/pin-user.svg`);
  userMarker.setPosition(positionToLatLngLiteral(position.currentPosition));
  updateScrollingElement("rt_stores_area");
}

function handlePhoneClick(store: Store, origin: CTAOrigin) {
  const eventName = origin === "list" ? "PhoneClickListing" : "PhoneClickMap";
  sendGa4DL(
    "Store",
    "Click",
    `${eventName}-${store.city} ${store.address1}-${currentDevice}-${retailtune.language}`,
    store.id
  );
}

function handleWhatsappClick(store: Store, origin: CTAOrigin) {
  const eventName = origin === "list" ? "WhatsappClickListing" : "WhatsappClickMap";
  sendGa4DL(
    "Store",
    "Click",
    `${eventName}-${store.city} ${store.address1}-${currentDevice}-${retailtune.language}`,
    store.id
  );
}

function handleDrivingDirectionsClick(store: Store, origin: CTAOrigin) {
  const eventName = origin === "list" ? "DirectionsClickListing" : "DirectionsClickMap";
  sendGa4DL(
    "Store",
    "Click",
    `${eventName}-${store.city} ${store.address1}-${currentDevice}-${retailtune.language}`,
    store.id
  );

  if (currentDevice === "desktop") {
    directionsDestinationStoreCode = store.storeCode;
    updateMapWithDirections();
  } else {
    window.open(getGoogleMapsLink(store), "_blank");
  }
}

function handleStoreInfoClick(store: Store, origin: CTAOrigin) {
  const eventName = origin === "list" ? "DetailsClickListing" : "DetailsClickMap";
  sendGa4DL(
    "Store",
    "Click",
    `${eventName}-${store.city} ${store.address1}-${currentDevice}-${retailtune.language}`,
    store.id
  );
}

window.addEventListener("load", () => {
  // try load the application
  let interval = setInterval(() => {
    try {
      if (!retailtune || !google) {
        throw new Error();
      }
      clearInterval(interval);
      main();
    } catch (e) {}
  }, 50);
});
